import { Component, OnInit } from '@angular/core';
//import { routeAnimations, LanguageService, BrowserDetectService, AnimationsService } from "@elemica/common";

declare var pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'customer-access';

  constructor() {}

  ngOnInit() {
    pendo.initialize({
      visitor: {
        // id: user.username,
        // email: user.email,
        // full_name: `${user.firstName} ${user.lastName}`,
        app_language: navigator.language,
      },
      account: {
      },
    });
  }
}
