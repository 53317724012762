import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {
  ApolloCoreModule
} from "@elemica/common";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";


const DECLARATIONS = [
  AppComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    ApolloCoreModule,
    HttpClientModule,
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent],
  exports: [ApolloCoreModule, ...DECLARATIONS],
})
export class AppModule { }
