import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';

@NgModule ( {
    imports:  [ 
        MatFormFieldModule,
         MatInputModule, 
         MatButtonModule, 
         MatDividerModule, 
         MatCardModule, 
         MatProgressSpinnerModule, 
         MatTabsModule, 
         MatIconModule , 
         MatSnackBarModule,
         MatDialogModule,
         MatToolbarModule,
         MatExpansionModule,
         MatMenuModule
        ],
    exports: [ 
        MatFormFieldModule, 
        MatInputModule,
        MatButtonModule, 
        MatDividerModule, 
        MatCardModule, 
        MatProgressSpinnerModule, 
        MatTabsModule, 
        MatIconModule, 
        MatSnackBarModule,
        MatDialogModule,
        MatToolbarModule,
        MatExpansionModule,
        MatMenuModule
        ]
})
export class MaterialModule {

}
