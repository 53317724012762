import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full' },
  { path: 'search',  loadChildren: () => import('./features/search/search.module').then((m) => m.SearchModule) },
  // tslint:disable-next-line:max-line-length
  { path: 'tracking-home/:shipmentNumber',  loadChildren: () => import('./features/tracking-home/tracking-home.module').then((m) => m.TrackingHomeModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
